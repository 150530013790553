import { axiosInstance } from '../../axios'
import { parseError, parseList } from '../../utils/validationmessage'

const POSTCreateUser = 'api/User/CreatePWAUser'

const createUser = async function (data) {
  try {
    console.log("creating user");            
    const response = await axiosInstance.publicAPIInstance.post(
      POSTCreateUser,
      data
    )
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

export const authenticateData = {
  createUser
}
