import { axiosInstance } from '../../axios'
import { parseError, parseList } from '../../utils/validationmessage'

const POSTCreateIncident = 'api/Incident/CreateIncident'
const POSTIncidentMessageAdded = 'api/Incident/IncidentMessageAdded'
const POSTGenerateToken = 'api/Incident/GenerateToken'
const GETMyIncident = 'api/Incident/MyIncidents'
const GETMyIncidentByKey = 'api/Incident/MyIncident'
const GETMyIncidentGetByKey = 'api/Incident/GetByKey'
const POSTCloseIncident = 'api/Incident/CloseIncident'

const createIncident = async function (data) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      POSTCreateIncident,
      data
    )
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

const closeIncident = async function (data) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      POSTCloseIncident,
      data
    )
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

const whenIncidentMessageAdded = async function (data) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      POSTIncidentMessageAdded,
      data
    )
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

const generateToken = async function (data) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      POSTGenerateToken,
      data
    )
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

const searchMyIncidents = async function (data) {
  try {
    const response = await axiosInstance.publicAPIInstance.post(
      GETMyIncident,
      data
    )
    return parseList(response)
  } catch (error) {
    return parseError(error)
  }
}

const searchMyIncident = async function (id) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(`${GETMyIncidentByKey}/${id}`)
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

const searchMyIncidentByKey = async function (id) {
  try {
    const response = await axiosInstance.publicAPIInstance.get(`${GETMyIncidentGetByKey}/${id}`)
    return response.data
  } catch (error) {
    return parseError(error)
  }
}

export const incidentData = {
  createIncident,
  closeIncident,
  whenIncidentMessageAdded,
  generateToken,
  searchMyIncidents,
  searchMyIncident,
  searchMyIncidentByKey
}
